import { useState, useEffect } from 'react';
import Header from '../../header/index';
import Footer from '../../footer/index';
import {Modal} from 'bootstrap';
import axios from 'axios';
import {downloadFile,setAuthorizationToken, addBodyClass} from '../../common/utils';
import PreviewModal from '../../common/previewModal/index';
import ModifierModal from '../../common/hiverangeValidationModifier/index';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAuthenticated  } from 'react-admin';
import {refreshTokenGen} from '../../../inMemoryJwt.js'
import { array } from 'yup';


function HivernageValidationPage() {
    useAuthenticated();
    const [fromError, setfromError] = useState('');
    const [modalImg, setImgUrl] = useState({title:'', imgUrl:''});
    const [modifierForm, setmodifierModal] = useState({title:'', id:'', i:{}});
    const [pagePost, setPostsData] = useState([]);
    const [eleEdition, setEdition] = useState('');
    const previewModal = (e, titleTxt, modalUrl) => {
        setImgUrl({title:titleTxt, imgUrl:modalUrl});
        let myModal = new Modal(document.getElementById('previewModalCenter'));
        myModal.show();
    }
    const modifierModalFun  = (e, titleTxt, modalUrl, i) => {
        setmodifierModal({title:titleTxt, id:modalUrl, index:i});
        let myModal = new Modal(document.getElementById('modifierModalCenter'));
        myModal.show();
    }
    useEffect(() => {
        let urlLocation = window.location.href;
        urlLocation = urlLocation.split('/');
        urlLocation = urlLocation[urlLocation.length - 2];
        setEdition(urlLocation);
        const tokenExpires = localStorage.getItem('tokenexpires');
        let refreshTOk = localStorage.getItem('refresh_token');
        if(parseInt(tokenExpires) < new Date().getTime()){
            refreshTokenGen(refreshTOk).then(challenge => {
                if(challenge){
                    getPosts(urlLocation);
                }
        })
        } else {
            getPosts(urlLocation);
        }
    }, []);
    const getPosts = (id) => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
            axios.get(`${process.env.REACT_APP_API_URL}/hvg-projects/${id}`, { headers: setAuthorizationToken(token) })
                .then(response => {
                    if (response.data.status === 401) {
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts(id);
                            }
                        })
                    } else if(response.data){
                        setPostsData(response.data);
                    }
                })
                .catch(error => {
                    // console.log('eror sec');
                    if(error.response.status === 401){
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts(id);    
                            }
                        })
                    }
            });
    }
    const validatorFun = (e, title, id, i) =>{
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
        const currentValide = pagePost?.designs[i];
        const formData = new FormData();
         formData.append('validated', true);
        if (token) {
            axios.patch(process.env.REACT_APP_API_URL + '/hvg-design/' + id, formData, { headers: setAuthorizationToken(token) })
            .then(response => {
                if (response.statusCode === 401) {
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            validatorFun(e, title, id, i);    
                        }
                    })
                } else {
                    const updateDesign = pagePost?.designs.findIndex(design => design.id === id);
                    let newDesignArray = pagePost?.designs;
                    newDesignArray[updateDesign]  = {...newDesignArray[updateDesign], validated:true};
                    let desingListUpdate = pagePost;
                    desingListUpdate.designs = newDesignArray;
                    setPostsData(desingListUpdate);
                    setfromError(true);
                }
            })
            .catch(error => {
                if (error?.response?.data?.statusCode === 401) {
                    refreshTokenGen(refreshToken).then(challenge => {
                        if(challenge){
                            validatorFun(e, title, id, i);    
                        }
                    })
                } else {
                    setfromError(false);
                }
            });
        }
    }
    const updatDesignData = () => {
        // console.log('hi');
    }
    const designArr = pagePost?.designs;
    // console.log(!designArr[0].validated)
    // console.log(designArr[0].hasCorrection)
    return (
        <>
        <Header/>
        <div className="breadCrumbSec">
            <div className="container siteContainer">
                <div className='row'>
                    <div className='col-12'>
                        <ul className="resetList breadCrumbList">
                            <li><a href="/hivernage" className="breadCrumbLink">Hivernage</a></li>
                            <li><span>validation</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>   
        <div className='pageDataSec withoutsubHead'>
                <div className='container siteContainer'>
                        <div className="forumValidationCnt">
                        <Scrollbars  style={{ height: 'calc(100vh - 358px)', width: '100%'}} 
                renderscrollbarhorizontal={props => <div {...props} className="scrollbar-horizontal" />}
                renderscrollbarvertical={props => <div {...props} className="scrollbar-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
             >
                                <div className='forumValidationCntInner'>
                            <div className='row'>
                                <div className='col-12'>
                                      <div className='row headerrow'>
                                            <div className='col personlisedModelCol'>
                                                <p>Vos maquettes personnalisées</p>
                                            </div>
                                            <div className='col previewCol'>
                                                <p className='text-center'>Prévisualisation</p>
                                            </div>
                                            <div className='col downloadCol'>
                                                <p className='text-center'>Télécharger</p>
                                            </div>
                                            <div className='col validatorCol'>
                                            </div>
                                            <div className='col modifiedCol'>
                                            </div>
                                        </div>
                                        { designArr && designArr.map((item, index) => (
                                        <div key={index} className='row eachColCnt'>
                                            <div className='col personlisedModelCol eachCol'>
                                                <p>{item.title}</p>
                                            </div>
                                            <div className='col previewCol eachCol'>
                                                 <p className='text-center'>
                                                    <button type='button' className='btn btn-img' onClick={(e) => previewModal(e, item?.title, item?.preview)}>
                                                        <img src={item.preview} className="img-fluid" alt="img" />
                                                    </button> 
                                                </p>
                                            </div>
                                            <div className='col downloadCol eachCol'>
                                                     <p className='text-center'>
                                                        <button type='button' onClick={(e) => downloadFile(e, item?.file)} className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></button>
                                                    </p>
                                            </div>
                                            <div className='col validatorCol'>
                                                    <p className='text-center'>
                                                        {(!item.validated) ? 
                                                            <button type='button' onClick={(e) => validatorFun(e, item?.title, item?.id, index)}  className={`btn btn-red btn-disable-${item.id} btn-rect ${((item.validated === false) && (item.hascorrection === true
                                                            ))  ? 'disabled':'' }`}>VALIDER</button>
                                                            :
                                                            <button type='button' className={`btn btn-success btn-valid btn-rect`}>validé</button>
                                                        }
                                                    </p>
                                            </div>
                                            {!item.validated &&
                                                <div className='col modifiedCol'>
                                                        <p className='text-center'>
                                                            <button type='button' onClick={(e) => modifierModalFun(e, item?.title, item?.id, index)} className={`btn btn-black btn-rect btn-disable-${item.id} ${((item.validated === false) && (item.hascorrection === true
))  ? 'disabled':'' }`}>MODIFIER</button>
                                                        </p>
                                                </div>
                                            }
                                        </div>
                                        ))}
                                    </div>
                                </div>
                                </div>
                            </Scrollbars>
                            </div>
                        </div>
                      </div>
                      <PreviewModal modalImgUrl={modalImg}/>
                      <ModifierModal passDataToParent={updatDesignData} postData = {pagePost} modifierFormData = {modifierForm} />
        <Footer/>
        </>
    );
  }
export default HivernageValidationPage;