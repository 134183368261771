import * as React from 'react';
import { TabbedForm, List, Edit, ShowButton,ReferenceField,useRecordContext, Link, useGetOne, ImageField, WithListContext, SelectInput,useListContext, Show,CreateButton, Labeled, SimpleShowLayout, ReferenceInput, ArrayField,TopToolbar, RichTextField,BooleanInput, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton} from 'react-admin';
import { date } from 'yup';
import { RichTextInput } from 'ra-input-rich-text';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useParams } from 'react-router-dom';


const getIdFunction = () => {
        let winLocation = window.location.href;
        let getProjectId = winLocation.split('/');
        return getProjectId[getProjectId.length - 1];
}
const DesignShowTitleNormal = () => {
    const record = useRecordContext();
    return <span>Design / {record ? `${record.title}` : ''}</span>;
};

const DesignShowTitle = () => {
    const record = useRecordContext();
    return <span>Design / {record ? `${record.title}` : ''}</span>;
};
export const HvgdesignCreate = () => (
    <Create>
        <SimpleForm>
                 <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                   <TextInput source="title" multiline={true} label="Title"  validate={[required()]} />
                            </Grid>
                            <Grid item xs={6}>
                                <BooleanInput label="Validated" source="validated" disabled hidden  defaultValue={false}/>
                            </Grid>
                            <Grid item xs={6}>
                               <BooleanInput label="hascorrection" source="hascorrection" disabled   defaultValue={false}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="project" label="Project"  disabled defaultValue={getIdFunction()} />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="correction" disabled hidden multiline={true} label="Correction"  defaultValue=" " />
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="preview" validate={[required()]}>
                                    <FileField source="src" title="title" validate={[required()]} />
                                </FileInput> 
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="file"  validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput> 
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="correctionfile" disabled defaultValue={null}>
                                    <FileField defaultValue={null} source="src" title="title" />
                                </FileInput>
                            </Grid>
                        </Grid>
                    </Box>
        </SimpleForm>
    </Create>
);  
export const HvgdesignEdit = () => (
    <Edit title={<DesignShowTitleNormal />}>
        <SimpleForm>
              <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                  <TextInput source="title" multiline={true} sx={{width:'100%'}} label="Title"  validate={[required()]}  />
                            </Grid>
                            <Grid item xs={3}>
                                <BooleanInput label="Validated" source="validated" />
                            </Grid>
                            <Grid item xs={3}>
                                <BooleanInput label="hascorrection" source="hascorrection" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="project.id" label="Project" readOnly defaultValue={getIdFunction()} sx={{width:'100%'}}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextInput source="correction" readOnly  multiline={true} label="Correction" sx={{width:'100%'}} />
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="preview">
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="file" validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput> 
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="correctionfile" disabled>
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                        </Grid>
                </Box>
        </SimpleForm>
    </Edit>
);
export const HvgdesignShow= () => (
    <Show title={<DesignShowTitleNormal />}>
             <SimpleShowLayout>
             <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Title">
                                            <TextField source="title" multiline={true} label="Title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Validated">
                                    <BooleanField label="Validated"  source="validated" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Correction">
                                    <TextField source="correction" label="Correction" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Project">
                                        <TextField source="project.id" label="Project" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                               <Labeled label="Preview">
                                   <ImageField source="preview" multiline={true} label="preview"  title="title"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="File">
                                    <FileField source="file" download={true} target="_blank" title="title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                    <Labeled label="Correction File">
                                        <FileField source="correctionfile" download={true} target="_blank" title="title" label="Correction"/>
                                    </Labeled>
                            </Grid>
                        </Grid>
                    </Box>
                </SimpleShowLayout>
        </Show>
);

export const HvgdesignList = () => (
    <List hasCreate={false}>
    <Datagrid>
        <TextField source="id" />
        <TextField source="title" label="Title" />
        <BooleanField sx={{'text-align':'center'}}  TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} label="Validated" source="validated" />
        <BooleanField sx={{'text-align':'center'}}  TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} label="Has Correction" source="hascorrection" />
        <TextField source="projectId" label="Project" />
        <EditButton />
        <ShowButton />
    </Datagrid>
</List>
)

const DesignEditButton = () => { 
    const { id, designsId } = useParams();
    return(
        <EditButton component={Link}
        to={`/admin/hvg-projects/${id}/hvg-designs/${designsId}`} label="Éditer" /> 
    )
}

const DesignShowButton = () => {
    const { id, designsId } = useParams();
    return(
        <ShowButton component={Link}
        to={`/admin/hvg-projects/${id}/hvg-designs/${designsId}/show`} label="Afficher" /> 
    )
}

const DesignShowActions = () => (
    <TopToolbar>
        <DesignEditButton/>
    </TopToolbar>
);
const DesignShowActionsOne = () => (
    <TopToolbar>
        <DesignShowButton/>
    </TopToolbar>
);

const DesignCreateButton = () => {
    const { id, projectId } = useParams();
    return(
        <CreateButton  component={Link}
        to={`/admin/hvg-editions/${id}/projects/${projectId}/design/create`} label="Créer" /> 
    )
}
const EmptyModel = () => {
    const { id } = useParams();
    const { data, isPending, error, refetch } = useGetOne('editions', { id: id } );
    return(
        <Box textAlign="center" m={1} sx={{width:'100%'}}>
            <div class="list-page">
                 <span class="RaList-noResults">
                        <div class="RaEmpty-message">
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium RaEmpty-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InboxIcon"><path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2m0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19z"></path></svg>
                            <p class="MuiTypography-root MuiTypography-h4 MuiTypography-paragraph">Pas encore de Models pour {data?.title}.</p>
                            <p class="MuiTypography-root MuiTypography-body1">Voulez-vous en créer un ?</p>
                        </div>
                        <div class="RaEmpty-toolbar">
                            <CreateButton/>
                        </div>
                </span>
            </div>
        </Box>
    )
};

const Empty = () => {
    const { id, projectId, designsId } = useParams();
    const { data, isPending, error, refetch } = useGetOne('editions', { id: id } );
    return(
        <Box textAlign="center" m={1} sx={{width:'100%'}}>
             <div class="list-page">
                 <span class="RaList-noResults">
                        <div class="RaEmpty-message">
                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium RaEmpty-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InboxIcon">
                                <path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2m0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19z"></path>
                        </svg>
                         <p class="MuiTypography-root MuiTypography-h4 MuiTypography-paragraph">Pas encore de Designs Pour {data?.title}.</p>
                        <p class="MuiTypography-root MuiTypography-body1">Voulez-vous en créer un ?</p>
                        </div>
                        <div class="RaEmpty-toolbar">
                            <DesignCreateButton />
                        </div>
                </span>
            </div>
        </Box>
    )
};
export const HvgDesignNestedEdit = () => {
    const { id, projectId, designsId } = useParams();
    return(
        <Edit resource={`hvg-design`} id={designsId} title={<DesignShowTitle />} redirect={`/admin/hvg-projects/${id}/designs`} actions={<DesignShowActionsOne />}>
            <SimpleForm>
                <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput source="title" multiline={true} sx={{width:'100%'}} label="Title"  validate={[required()]}  />
                                </Grid>
                                <Grid item xs={3}>
                                    <BooleanInput label="Validated" source="validated" />
                                </Grid>
                                <Grid item xs={3}>
                                    <BooleanInput label="hascorrection" source="hascorrection" />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="project.id" label="Project" readOnly defaultValue={projectId} sx={{width:'100%'}}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="correction" readOnly  multiline={true} label="Correction" sx={{width:'100%'}} />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="preview">
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="file" validate={[required()]} >
                                        <FileField source="src" title="title" />
                                    </FileInput> 
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="correctionfile" disabled>
                                        <FileField  source="src" title="title" />
                                    </FileInput>
                                </Grid>
                            </Grid>
                    </Box>
            </SimpleForm>
        </Edit>
    )
};

export const HvgDesignNestedShow= () => {
    const { id, projectId, designsId } = useParams();
    return(
        <Show resource={`hvg-design`} title={<DesignShowTitle />} id={designsId} actions={<DesignShowActions />}>
             <SimpleShowLayout>
                 <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Title">
                                            <TextField source="title" multiline={true} label="Title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Validated">
                                    <BooleanField label="Validated" source="validated" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Correction">
                                    <TextField source="correction" label="Correction" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Project">
                                        <TextField source="project.id" label="Project" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                               <Labeled label="Preview">
                                    <ImageField source="preview" multiline={true} label="preview"  title="title"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="File">
                                    <FileField download={true} target="_blank" source="file" title="title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Correction File">
                                    <FileField download={true} target="_blank" source="correctionfile" title="title" label="Correction"/>
                                </Labeled>
                            </Grid>
                        </Grid>
                    </Box>
            </SimpleShowLayout>
        </Show>
    )
};
const ListActions = () => {
    const { id, projectId } = useParams();
    return(
            <TopToolbar>
                <CreateButton  component={Link}   to={`/admin/hvg-editions/${id}/projects/${projectId}/design/create`} label="Créer" /> 
             </TopToolbar>
    )
};
export const HvgDesignProjectList = () => {
    const { id, designId } = useParams();
    const record = useRecordContext();
    console.log(record);
    return(
     <List resource={`hvg-projects/${id}/designs`} hasCreate={true} actions={<ListActions/>} >
            <Datagrid>
                <TextField source="id" />
                <TextField source="title" label="Title" />
                <BooleanField sx={{'text-align':'center'}}  TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} label="Validated" source="validated"/>
                <BooleanField sx={{'text-align':'center'}} TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} label="Has Correction" source="hascorrection" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
}
const HvgDesignListTitle = () => {
    const { id, projectId } = useParams();
    const { data, isPending, error, refetch } = useGetOne('projects', { id: projectId } );
    return <span> {data ? `${data.edition.title} / ${data.user.lastName} / Designs` : ''}</span>;
}

export const HvgDesignNestedList = () => {
    const {id, projectId, designId } = useParams();
    return(
     <List resource={`hvg-projects/${projectId}/designs`} hasCreate={true} actions={<ListActions/>} empty={<Empty/>} title={<HvgDesignListTitle/>}>
            <Datagrid>
                <TextField source="id" />
                <TextField source="title" label="Title" />
                <BooleanField sx={{'text-align':'center'}} label="Validated" TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} source="validated" />
                <BooleanField sx={{'text-align':'center'}} label="Has Correction" TrueIcon={TaskAltIcon} FalseIcon={HighlightOffIcon} source="hascorrection" />
                <EditButton />
                <ShowButton />
            </Datagrid>
        </List>
    );
}
export const HvgDesignProjectCreate = () => {
    const {id, projectId} = useParams();
    return (
        <Create resource={`hvg-design`} redirect={`/admin/hvg-projects/${id}/designs`}>
            <SimpleForm>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput source="title" multiline={true} label="Title"  sx={{width:'100%'}} validate={[required()]} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BooleanInput label="Validated" source="validated" disabled hidden  defaultValue={false}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <BooleanInput label="hascorrection" source="hascorrection" disabled sx={{width:'100%'}}  defaultValue={false}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="project" label="Project" disabled defaultValue={id} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="correction" disabled hidden multiline={true} label="Correction"  defaultValue=" " />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="preview" validate={[required()]}>
                                        <FileField source="src" title="title" validate={[required()]} />
                                    </FileInput> 
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="file"  validate={[required()]} >
                                        <FileField source="src" title="title" />
                                    </FileInput> 
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="correctionfile" disabled defaultValue={null}>
                                        <FileField defaultValue={null} source="src" title="title" />
                                    </FileInput>
                                </Grid>
                            </Grid>
                        </Box>
            </SimpleForm>
        </Create>
    )
}

export const HvgDesignNestedCreate = () => {
    const {id, projectId} = useParams();
    return (
        <Create resource={`hvg-design`} redirect={`/admin/hvg-editions/${id}/projects/${projectId}/design`}>
            <SimpleForm>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput source="title" multiline={true} label="Title" sx={{width:'100%'}}  validate={[required()]} />
                                </Grid>
                                <Grid item xs={6}>
                                    <BooleanInput label="Validated" source="validated" disabled hidden  defaultValue={false}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <BooleanInput label="hascorrection" source="hascorrection" disabled   defaultValue={false}/>
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="project" label="Project" sx={{width:'100%'}} disabled defaultValue={projectId} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="correction" disabled hidden multiline={true} label="Correction"  defaultValue=" " />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="preview" validate={[required()]}>
                                        <FileField source="src" title="title" validate={[required()]} />
                                    </FileInput> 
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="file"  validate={[required()]} >
                                        <FileField source="src" title="title" />
                                    </FileInput> 
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="correctionfile" disabled defaultValue={null}>
                                        <FileField defaultValue={null} source="src" title="title" />
                                    </FileInput>
                                </Grid>
                            </Grid>
                        </Box>
            </SimpleForm>
        </Create>
    )
}