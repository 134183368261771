import * as React from 'react';
import { TabbedForm, List, UrlField, Edit,email,BooleanInput,TopToolbar, useRedirect,useGetOne, Labeled,useRecordContext,Link, ShowButton,  TabbedShowLayout,  Show, SimpleShowLayout, ReferenceInput, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton } from 'react-admin';
import { setAuthorizationToken } from '../../common/utils';
import inMemoryJWT from '../../../inMemoryJwt';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import ServicesIcon from '@mui/icons-material/DesignServices';

const createDesign = () => {
    let winLocation =  window.location.href;
    let getArray  = winLocation.split('/');
    let currentId = getArray[getArray.length - 2];
    return currentId;
}
const validateEmail = email();
const projectNotify = (e) => {
    const currentId = e.target.closest(`tr`).querySelector('.column-id').innerText;
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    axios.post(`${process.env.REACT_APP_API_URL}/hvg-projects/notify/${currentId}`, void 0, { headers: setAuthorizationToken(token) })
    .then(response => { 
        if (response.statusCode === 401) {
            inMemoryJWT.refreshTokenGen(refreshToken).then(challenge => {
                if(challenge){
                    projectNotify(e);    
                }
            })
        } else if(response.data){
             
        }
    })
    .catch(error => {
        if (error.response.data) {
            // console.log('failure');
        }
});
}
const HvgProjectShowTitle = () => {
    const record = useRecordContext();
    return <span> {record ? `${record.edition.title} / Projects / ` : ''}  {record ? `${record.user.lastName}` : ''}</span>;
};
const HvgProjectShowTitleNormal = () => {
    const record = useRecordContext();
    return <span>Project / {record ? `${record.user.lastName}` : ''}</span>;
};

const HvgProjectListTitle = () => {
    const { id } = useParams();
    const { data, isPending, error, refetch } = useGetOne('hvg-editions', { id: id } );
    return <span> {data ? `${data.title} / Projects` : ''}</span>;
}
const EmptyProject = () => {
    const { id } = useParams();
    const { data, isPending, error, refetch } = useGetOne('hvg-editions', { id: id } );
    return(
        <Box textAlign="center" m={1} sx={{width:'100%'}}>
            <div class="list-page">
                 <span class="RaList-noResults">
                        <div class="RaEmpty-message">
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium RaEmpty-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InboxIcon"><path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2m0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19z"></path></svg>
                            <p class="MuiTypography-root MuiTypography-h4 MuiTypography-paragraph">Pas encore de Projects pour {data?.title}.</p>
                        </div>
                       
                </span>
            </div>
        </Box>
    )
};
export const HvgprojectList = () => (
    <List>
        <Datagrid>
            <TextField source="id" label="Id" />
            <TextField source="edition.title" label="Edition" />
            <TextField source="user.lastName" label="User"/>
            <EditButton />
            <ShowButton />
             <HvgDesignProjectListButton/>
            <Button label="Notify" onClick={projectNotify} value={record => record.id} />
           
        </Datagrid>
    </List>
);
export const HvgprojectEdit = () => (
    <Edit title={<HvgProjectShowTitleNormal />}>
        <SimpleForm>
              <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                  <TextInput source="id" label="id" readOnly sx={{width:'100%'}} />
                            </Grid>
                        <Grid item xs={12}> 
                                    <FileInput source="el1" label="Chargez le document complété avec votre texte"  validate={[required()]}>
                                        <FileField source="src"  title="title"  />
                                    </FileInput> 
                        </Grid>
                        <Grid item xs={12}> 
                                <FileInput source="el2" label="Votre repiquage (obligatoire)" validate={[required()]}>
                                    <FileField source="src"  title="title"  />
                                </FileInput> 
                        </Grid>
                        <Grid item xs={12}> 
                            <FileInput source="el3" label="Votre logo (obligatoire)" validate={[required()]} >
                                <FileField source="src" title="title" />
                            </FileInput>
                        </Grid>
                        <Grid item xs={12}> 
                            <FileInput source="el4" label="Votre QR code" >
                                <FileField source="src" title="title" />
                            </FileInput>
                        </Grid>
                        <Grid item xs={12}> 
                            <FileInput source="shipping" label="Votre base de données clients" validate={[required()]}>
                                <FileField source="src" title="title" />
                            </FileInput>
                        </Grid>
                        <Grid item xs={6}> 
                                <TextInput source="command[commandeFormOne][customerObjective]" sx={{width:'100%'}} label="Votre objectif signé"  />
                        </Grid>
                        <Grid item xs={6}> 
                                <NumberInput source="command[commandeFormOne][quantityOrdered]"  sx={{width:'100%'}} label=">="  />
                        </Grid>
                        <Grid item xs={12}> 
                            <ArrayInput source="command[commandeFormTwo]" sx={{width:'100%'}}>
                                <SimpleFormIterator sx={{width:'100%'}} inline>
                                    <TextInput source="basics" label="Bases" helperText={false} />
                                    <NumberInput source="quantity" label="Quantité" helperText={false} />
                                    <TextInput source="address" address="Adresse" helperText={false} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        <Grid item xs={6}> 
                            <TextInput source="user[id]" sx={{width:'100%'}} label="user id" readOnly />
                        </Grid>
                        <Grid item xs={6}> 
                            <TextInput source="edition[id]" sx={{width:'100%'}} label="Edition id" readOnly />
                        </Grid>
                    </Grid>
            </Box>
        </SimpleForm>
    </Edit>
);

export const HvgprojectShow = (props) => {
    return (
        <Show title={<HvgProjectShowTitleNormal />}>
            <SimpleShowLayout>
            <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Id">
                                          <TextField label="Id" source="id" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Edition">
                                    <TextField source="edition.title" label="Edition" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="User">
                                    <TextField source="user.lastName" label="User" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Chargez le document complété avec votre texte ">
                                        <UrlField  source="el1"  label="Chargez le document complété avec votre texte "/>
                                  </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                   <Labeled label="Votre repiquage">
                                          <UrlField  source="el2"  label="Votre repiquage"/>
                                    </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Votre logo">
                                            <UrlField source="el3"  label="Votre logo"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Votre QR code">
                                            <UrlField source="el4"  label="Votre QR code"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Déposer vos informations clients">
                                           <UrlField source="shipping" label="Déposer vos informations clients"/>
                                    </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                  <Labeled label="Objectif client">
                                     <TextField source="command.commandeFormOne.customerObjective" label="Objectif client" />
                                    </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                  <Labeled label="Quantité commandé">
                                     <TextField source="command.commandeFormOne.quantityOrdered" label="Quantité commandé" />
                                 </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                      <Labeled label="Votre commande" sx={{width:'100%'}}>
                                            <ArrayField source="command.commandeFormTwo" label="Votre commande">
                                                <Datagrid sx={{width:'100%'}} bulkActionButtons={false}>
                                                    <TextField source="basics" label="Bases" />
                                                    <TextField source="quantity" label="Quantité" />
                                                    <TextField source="address" label="Adresse" />
                                                </Datagrid>
                                            </ArrayField>
                                        </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                <a href={`/admin/hvg-design/create/${createDesign()}`}
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary css-27k8g8-MuiButtonBase-root-MuiButton-root-RaButton-root" tabindex="0" aria-label="Show Design" onClick={()=> createDesign() }><span class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeSmall css-cstir9-MuiButton-startIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RemoveRedEyeIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"></path></svg></span>Create Design<span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></a>
                            </Grid>
                </Grid>
                </Box>
                </SimpleShowLayout>
        </Show>
   );
};

const HvgDesignProjectListButton = () => {
    const projects = useRecordContext();
    return (
        <Button
            component={Link}
            to={`./${projects?.id}/designs`}
            startIcon={<ServicesIcon />}
            label='Designs'
        >
            Design
        </Button>
    );
};

const HvgDesignListButton = () => {
    const projects = useRecordContext();
    return (
        <Button
            component={Link}
            to={`./${projects?.id}/design`}
            startIcon={<ServicesIcon />}
            label='Designs'
        >
            Design
        </Button>
    );
};

export const HvgProjectNestedList = () => {
    const { id } = useParams();
    return(
        <List resource={`hvg-editions/${id}/projects`} title={<HvgProjectListTitle />} empty={<EmptyProject/>}>
            <Datagrid>
                <TextField source="id" label="Id" />
                <TextField source="user.lastName" label="User"/>
                <EditButton />
                <HvgDesignListButton/>
                <ShowButton />
                <Button label="Notify" onClick={projectNotify} value={record => record.id} />
            
            </Datagrid>
        </List>
    )
};
const ProjectEditButton = () => { 
    const { id, projectId } = useParams();
    return(
        <EditButton component={Link}
        to={`/admin/hvg-editions/${id}/projects/${projectId}`} label="Éditer" /> 
    )
}

const ProjectShowButton = () => {
    const { id, projectId } = useParams();
    return(
        <ShowButton component={Link}
        to={`/admin/hvg-editions/${id}/projects/${projectId}/show`} label="Afficher" /> 
    )
}

const ProjectShowActions = () => (
    <TopToolbar>
        <ProjectEditButton/>
    </TopToolbar>
);
const ProjectShowActionsOne = () => (
    <TopToolbar>
        <ProjectShowButton/>
    </TopToolbar>
);

export const HvgProjectNestedEdit = () => {    
    const { id, projectId } = useParams();
    return(
        <Edit resource={`hvg-projects`} title={<HvgProjectShowTitle />} id={projectId} redirect={`/admin/hvg-editions/${id}/projects`} actions={<ProjectShowActionsOne />}>
            <SimpleForm>
                <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextInput source="id" label="id" readOnly sx={{width:'100%'}} />
                                </Grid>
                                
                            <Grid item xs={12}> 
                                        <FileInput source="el1">
                                            <FileField source="src" label="Chargez le document complété avec votre texte" title="title" />
                                        </FileInput> 
                            </Grid>
                            <Grid item xs={12}> 
                                    <FileInput source="el2">
                                        <FileField source="src" label="Votre repiquage" title="title" />
                                    </FileInput> 
                            </Grid>
                            <Grid item xs={12}> 
                                <FileInput source="el3" label="Votre logo" validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}> 
                                <FileInput source="el4" label="Votre QR code">
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}> 
                                <FileInput source="shipping" label="Votre base de données clients" validate={[required()]}>
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                            <Grid item xs={6}> 
                                    <TextInput source="command[commandeFormOne][customerObjective]" sx={{width:'100%'}} label="Votre objectif signé"  />
                            </Grid>
                            <Grid item xs={6}> 
                                    <NumberInput source="command[commandeFormOne][quantityOrdered]"  sx={{width:'100%'}} label=">="  />
                            </Grid>
                            <Grid item xs={12}> 
                                <ArrayInput source="command[commandeFormTwo]" sx={{width:'100%'}}>
                                    <SimpleFormIterator sx={{width:'100%'}} inline>
                                        <TextInput source="basics" label="Bases" helperText={false} />
                                        <NumberInput source="quantity" label="Quantité" helperText={false} />
                                        <TextInput source="address" address="Adresse" helperText={false} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            </Grid>
                            <Grid item xs={6}> 
                                <TextInput source="user[id]" sx={{width:'100%'}} label="user id" readOnly />
                            </Grid>
                            <Grid item xs={6}> 
                                <TextInput source="edition[id]" sx={{width:'100%'}} label="Edition id" readOnly />
                            </Grid>
                        </Grid>
                </Box>
            </SimpleForm>
        </Edit>
    )
};

export const HvgProjectNestedShow = (props) => {
    const { id, projectId } = useParams();
    return (
        <Show resource={`hvg-projects`} title={<HvgProjectShowTitle />} id={projectId} actions={<ProjectShowActions />}> 
            <SimpleShowLayout>
            <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Id">
                                          <TextField label="Id" source="id" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Edition">
                                    <TextField source="edition.title" label="Edition" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="User">
                                    <TextField source="user.lastName" label="User" />
                                </Labeled>
                            </Grid>
                            
                            <Grid item xs={12}>
                                  <Labeled label="Chargez le document complété avec votre texte">
                                        <UrlField  source="el1"  label="Chargez le document complété avec votre texte"/>
                                  </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                   <Labeled label="Votre repiquage">
                                          <UrlField  source="el2"  label="Votre repiquage"/>
                                    </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Votre logo">
                                            <UrlField source="el3"  label="Votre logo"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Votre QR code">
                                            <UrlField source="el4"  label="Votre QR code"/>
                                </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                  <Labeled label="Déposer vos informations clients">
                                           <UrlField source="shipping" label="Déposer vos informations clients"/>
                                    </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                  <Labeled label="Objectif client">
                                     <TextField source="command.commandeFormOne.customerObjective" label="Objectif client" />
                                    </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                  <Labeled label="Quantité commandé">
                                     <TextField source="command.commandeFormOne.quantityOrdered" label="Quantité commandé" />
                                 </Labeled>
                            </Grid>
                            <Grid item xs={12}>
                                      <Labeled label="Votre commande" sx={{width:'100%'}}>
                                            <ArrayField source="command.commandeFormTwo" label="Votre commande">
                                                <Datagrid sx={{width:'100%'}} bulkActionButtons={false}>
                                                    <TextField source="basics" label="Bases" />
                                                    <TextField source="quantity" label="Quantité" />
                                                    <TextField source="address" label="Adresse" />
                                                </Datagrid>
                                            </ArrayField>
                                        </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                <a href={`/admin/hvg-design/create/${createDesign()}`}
                class="MuiButtonBase-root MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary MuiButton-root MuiButton-outlined MuiButton-outlinedPrimary MuiButton-sizeSmall MuiButton-outlinedSizeSmall MuiButton-colorPrimary css-27k8g8-MuiButtonBase-root-MuiButton-root-RaButton-root" tabindex="0" aria-label="Show Design" onClick={()=> createDesign() }><span class="MuiButton-icon MuiButton-startIcon MuiButton-iconSizeSmall css-cstir9-MuiButton-startIcon"><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="RemoveRedEyeIcon"><path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5M12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5m0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3"></path></svg></span>Create Design<span class="MuiTouchRipple-root css-8je8zh-MuiTouchRipple-root"></span></a>
                            </Grid>
                </Grid>
                </Box>
                </SimpleShowLayout>
        </Show>
   );
};

