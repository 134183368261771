import { useState, useEffect } from 'react';
import Header from '../../header/index';
import Footer from '../../footer/index';
import { Modal} from 'bootstrap';
import axios from "axios";
import {downloadFile, addBodyClass, setAuthorizationToken, dateConvert} from '../../common/utils';
import PreviewModal from '../../common/previewModal/index';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAuthenticated  } from 'react-admin';
import {refreshTokenGen} from '../../../inMemoryJwt.js';

function HivernageDetailPage() {
    useAuthenticated();
    const [modalImg, setImgUrl] = useState({title:'', imgUrl:''});
    const [pagePost, setPostsData] = useState([]);
    const previewModal = (e, titleTxt, modalUrl) => {
        setImgUrl({title:titleTxt, imgUrl:modalUrl});
        let myModal = new Modal(document.getElementById('previewModalCenter'));
        myModal.show();
    }
    useEffect(() => {
        let urlLocation = window.location.href;
            urlLocation = urlLocation.split('/');
            urlLocation = urlLocation.pop();
            const tokenExpires = localStorage.getItem('tokenexpires');
            let refreshTOk = localStorage.getItem('refresh_token');
            if(parseInt(tokenExpires) < new Date().getTime()){
                refreshTokenGen(refreshTOk).then(challenge => {
                    if(challenge){
                        getPosts(urlLocation);   
                    }
                })
            } else {
                getPosts(urlLocation);
            }
    }, []);
    const getPosts = (id) => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
            axios.get(`${process.env.REACT_APP_API_URL}/hvg-editions/${id}`, { headers: setAuthorizationToken(token) })
                .then(response => {
                    if (response?.status === 401) {
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts(id);    
                            }
                        })
                    } else if(response.data){
                         setPostsData(response.data);
                    }
                })
                .catch(error => {
                    if(error.response.status === 401){
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts(id);    
                            }
                        })
                    }
            });
    }
    
    const modelPostArr  = pagePost.models;
    return (
        <>
        <Header/>
          <div className="breadCrumbSec">
              <div className="container siteContainer">
                    <div className='row'>
                      <div className='col-12'>
                          <ul className="resetList breadCrumbList">
                              <li><a href="/hivernage" className="breadCrumbLink">Hivernage</a></li>
                              <li><span>{pagePost.title}</span></li>
                          </ul>
                      </div>
                    </div>
              </div>
          </div>   
         <div className='pageDataSec'>
            <div className='farmForumPage'>
                <div className='container siteContainer'>
                      <div className='row'>
                          <div className='col-12'>
                              <p className='text-end farmForumHead'>{pagePost.title}</p>
                          </div>
                      </div>
                      <div className='forumPageInner'>
                      <Scrollbars  style={{ height: 'calc(100vh - 392px)', width: '100%'}} 
                renderscrollbarhorizontal={props => <div {...props} className="scrollbar-horizontal" />}
                renderscrollbarvertical={props => <div {...props} className="scrollbar-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
             >
                        <div className="forumDetailCnt">
                            <div className='listCnt'>
                           
                                 <div className='row'>
                                <div className='col-12'>
                                    { modelPostArr?.length > 0 &&
                                    <div className='row headerrow'>
                                            <div className='col dateCol'>
                                                <p>Date</p>
                                            </div>
                                            <div className='col titleCol'>
                                                <p>Titre</p>
                                            </div>
                                            <div className='col previewCol'>
                                                <p>Prévisualiser</p>
                                            </div>
                                            <div className='col downloadCol'>
                                                <p>Télécharger tout</p>
                                            </div>
                                        </div>
                                    }
                                        { modelPostArr && modelPostArr.map((item, index) => (
                                                <div key={index} className='row eachColCnt'>
                                                    <div className='col dateCol eachCol'>
                                                        <p>{dateConvert(item?.date)}</p>
                                                    </div>
                                                    <div className='col titleCol eachCol'>
                                                        <p>{item.title}</p>
                                                    </div>
                                                    <div className='col previewCol eachCol'>
                                                        <p>
                                                        <button type='button' className='btn btn-img' onClick={(e) => previewModal(e, item?.title, item?.preview)}>
                                                                <img src={item.preview} className="img-fluid" alt="img" />
                                                            </button>
                                                        </p>
                                                    </div>
                                                    <div className='col downloadCol eachCol'>
                                                            <p><button onClick={(e) => downloadFile(e, item?.content)} className="donwloadLink"><i className="iconmoon icon-filedownload downloadfileIcon"></i></button></p>
                                                    </div>
                                                </div>
                                        ))
                                        }
                                        </div>
                                  </div>
                                  
                            </div>
                        </div>
                    </Scrollbars>
                      </div>
                    </div>
                </div>
          </div>
          <PreviewModal modalImgUrl={modalImg}/>
        <Footer/>
        </>
    );
  }
export default HivernageDetailPage;