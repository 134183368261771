import { useEffect, useState } from 'react';
import Header from '../../header/index';
import Footer from '../../footer/index';
import axios from "axios";
import { Modal} from 'bootstrap';
import {downloadFile, addBodyClass, setAuthorizationToken} from '../../common/utils';
import SuggestedModal from '../../common/suggestedModal/index';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useAuthenticated, useGetIdentity  } from 'react-admin';
import {refreshTokenGen} from '../../../inMemoryJwt.js';
import '../../../styles.scss';
function HivernagePage() {
    useAuthenticated();
    const [suggestedModalDetail, setMoalDetail] = useState({name:'', id:'', userEmail:''});
    const [pagePost, setPostsData] = useState([]);
    const [projectPost, setProjectData] = useState([]);
    const [activeEdition, setActiveEditon] = useState({index:0, title:'', id:'', state:'',design:false})
    const userIdentity = useGetIdentity();
    const suggestedModal  = (e, name, id) => {
        setMoalDetail({name:name, id:id, userEmail:userIdentity?.data?.email});
        let myModal = new Modal(document.getElementById('suggestedModalCenter'));
        myModal.show();
    }

    useEffect(() => {
        let getUserId = JSON.parse(localStorage.getItem('userDetails'));
        const tokenExpires = localStorage.getItem('tokenexpires');
        let refreshTOk = localStorage.getItem('refresh_token');
        if(parseInt(tokenExpires) < new Date().getTime()){
            refreshTokenGen(refreshTOk).then(challenge => {
                if(challenge){
                    getPosts(getUserId?.id);
                }
            })
        } else {
            getPosts(getUserId?.id);
        }
        addBodyClass();
        function handleResize() {
            
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);
    const getPosts = ($userId) => {
        const token = localStorage.getItem('token');
        const refreshToken = localStorage.getItem('refresh_token');
            axios.get(`${process.env.REACT_APP_API_URL}/hvg-editions/findAllWithProjects/${$userId}`, { headers: setAuthorizationToken(token) })
                .then(response => {
                    if (response.status === 401) {
                        refreshTokenGen(refreshToken).then(challenge => {
                            if(challenge){
                                getPosts($userId);
                            }
                        })
                    } else if(response.data){
                        setPostsData(response.data);
                       const allData =  response.data;
                       const responseLastData = allData?.length - 1;
                       let designVal = allData[responseLastData]?.project?.designs && allData[responseLastData]?.project?.designs.length > 0 ? true :false;
                       let proId = allData[responseLastData]?.project?.id;
                       const activeForum = {index:responseLastData, title:allData[responseLastData]?.fulltitle, id:allData[responseLastData]?.id, state:allData[responseLastData]?.project?.state, proId:proId, design:designVal }
                       setActiveEditon(activeForum);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        refreshTokenGen(refreshToken)?.then(challenge => {
                            if(challenge){
                                getPosts($userId);
                            }
                        })
                    }
            });
    }
    const youElements = (e, activateId, prodId) => {
        localStorage.setItem('projectId', prodId);
        window.location.href = `/hivernage/${activateId}/vos-elements`;
    }
    const activeEditionFun = (e, i, titleTxt, slugTxt, stateTxt, proId, design) => {
        let designVal = design && design.length > 0 ? true :false;
        const activeForum = {index:i, title:titleTxt, id:slugTxt, state:stateTxt, proId:proId, design:designVal}
        localStorage.setItem('activeEdition', titleTxt);
        setActiveEditon(activeForum);
    }
    const elementsPage = (e, id) =>{
        // if(activeEdition.proId){
        //         localStorage.setItem(proId)
        // }
    }
    return (
        <>
        <Header/>
          <div className="breadCrumbSec">
              <div className="container siteContainer">
                    <div className='row'>
                      <div className='col-12'>
                          <ul className="resetList breadCrumbList">
                              <li><span>Hivernage</span></li>
                          </ul>
                      </div>
                    </div>
              </div>
          </div>
          <div className='farmForumPage'>

            <div className='pageDataSec'>
                <div className='container siteContainer'>
                      <div className='row'>
                          <div className='col-12'>
                              <p className='text-end farmForumHead'>{activeEdition.title}</p>
                          </div>
                      </div>
                      <div className='forumPageInner'>
                      <Scrollbars  style={{ height: 'calc(100vh - 392px)', width: '100%'}}
                renderscrollbarhorizontal={props => <div {...props} className="scrollbar-horizontal" />}
                renderscrollbarvertical={props => <div {...props} className="scrollbar-vertical"/>}
                renderThumbHorizontal={props => <div {...props} className="thumb-horizontal"/>}
                renderThumbVertical={props => <div {...props} className="thumb-vertical"/>}
             >
                        <div className='row'>
                          {pagePost.length > 0 &&
                          <>
                            <div className='col-4 sideNavBarCol'>
                                <div className='sideNavBarInner'>
                                    <p className='editionHeading'>éditions</p>
                                        <ul className='resetList editionList'>
                                            { pagePost && pagePost.map((item, index) => (
                                                <li key={index}><button onClick={(e) => activeEditionFun(e, index, item.fulltitle, item.id, item?.project?.state, item?.project?.id, item?.project?.designs )} className={`editionItemTxt ${activeEdition.index === index ? 'active': ''}`}><span className='iconmoon icon-iconright arrowIcon'></span><span className='txt'>{item.fulltitle}</span></button></li>
                                                ))
                                            }
                                        </ul>
                                </div>
                            </div>
                            <div className='col-8 formDetailCol'>
                                <div className='farmCntInner '>
                                    <div className='formCntList'>
                                        {pagePost.length <= 0 &&
                                            <div className='pageLoading'>
                                            </div>
                                        }
                                        {pagePost.length > 0 &&
                                        <div className='row'>
                                                <div className='col-4 eachFarmItem'>
                                                    <a href={`/hivernage/${activeEdition.id}`} className="eachFarmItemLink">
                                                        <span className='text-center fram_title'>1. Maquette</span>
                                                    <span className='text-center'><i className="iconmoon icon-filefolder folderIcon"></i></span>
                                                    </a>
                                                </div>
                                                <div className='col-4 eachFarmItem'>
                                                    {/* {(activeEdition.state !== 'waiting_puck') ? */
                                                    <a onClick={(e) => youElements(e, activeEdition.id, activeEdition.proId)} className="eachFarmItemLink yourEleMenu">
                                                        <span className='text-center fram_title'>2. Vos éléments et votre commande</span>
                                                        <span className='text-center'><i className="iconmoon icon-filefolder folderIcon"></i></span>
                                                    </a>
                                                    /* :
                                                    <a href="#" className="eachFarmItemLink disbaleBtn">
                                                        <span className='text-center fram_title'>Vos éléments</span>
                                                        <span className='text-center'><i className="iconmoon icon-filefolder folderIcon"></i></span>
                                                    </a> */
                                                }
                                                </div>
                                                <div className='col-4 eachFarmItem'>
                                                    <>
                                                    {((activeEdition.state !== 'waiting_user') && (activeEdition.state !== 'waiting_puck') && (activeEdition.state !== undefined)) || (activeEdition.design) ?
                                                        <a href={`/hivernage/${activeEdition.proId}/validation`} className={`eachFarmItemLink`}>
                                                        <span className='text-center fram_title'>3. Validation</span>
                                                        <span className='text-center'><i className="iconmoon icon-filefolder folderIcon"></i></span>
                                                    </a>
                                                    :
                                                    <a href="#" className={`eachFarmItemLink disbaleBtn`}>
                                                        <span className='text-center fram_title'>3. Validation</span>
                                                        <span className='text-center'><i className="iconmoon icon-filefolder folderIcon"></i></span>
                                                    </a>
                                                }
                                            </>
                                                </div>
                                               
                                        </div>
                                    }
                                    </div>
                                </div>
                            </div>    
                            </>
                         }
                             {pagePost.length <= 0 &&
                                <div className='pageLoading'>
                                        <p className='nodataText'>Aucune édition disponible</p>
                                </div>
                            }
                          </div>
                          </Scrollbars >
                      </div>
                    </div>
                </div>

          </div>
        <Footer/>
        </>
    );
  }
export default HivernagePage;
