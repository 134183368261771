import * as React from 'react';
import { TabbedForm, List, Edit, ShowButton, SelectInput,useGetOne, Show,Labeled,Link, CreateButton, SimpleShowLayout, ReferenceInput,ImageField, TopToolbar, useRecordContext, ArrayField, ReferenceManyField, FileInput, FileField, Datagrid, Button, ArrayInput, SimpleFormIterator, NumberInput, TextField, DateField, BooleanField, Create, SimpleForm, TextInput, DateInput, required, EditButton, ReferenceField} from 'react-admin';
import { date } from 'yup';
import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const ModelShowTitle = () => {
    const record = useRecordContext();
    console.log(record);
    return <span> {record ? `${record.edition.title} / ` : ''}  {record ? `${record.title}` : ''}</span>;
};

const ModelShowTitleNormal = () => {
    const record = useRecordContext();
    return <span>Model / {record ? `${record.title}` : ''}</span>;
};

export const HvgmodelCreate = () => (
    <Create redirect="list">
        <SimpleForm>
                <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                               <DateInput label="Publication date" sx={{width:'100%'}} source="date" defaultValue={new Date()} />
                            </Grid>
                            <Grid item xs={6}>
                                 <TextInput source="title" multiline={true} sx={{width:'100%'}} label="Title"  validate={[required()]}  />
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="preview"  validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                            <Grid item xs={12}>
                                <FileInput source="content"  validate={[required()]} >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </Grid>
                    {/* <TextInput source="preview" multiline={true} label="Preview" />
                    <TextInput source="content" multiline={true} label="Content" /> */}
                             <Grid item xs={6}>
                                <ReferenceInput label="edition" source="edition" reference="hvg-editions"> 
                                        <SelectInput source="title" sx={{width:'100%'}} optionText="title" validate={required()} />
                                </ReferenceInput>
                             </Grid>
                        </Grid>
                    </Box>
        </SimpleForm>
    </Create>
);

export const HvgmodelEdit = () => (
    <Edit title={<ModelShowTitleNormal />}>
        <SimpleForm>
                 <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                              <Grid item xs={6}>
                                    <DateInput label="Publication date" sx={{width:'100%'}} source="date" defaultValue={date} />
                               </Grid>
                               <Grid item xs={6}>
                                     <TextInput source="title" sx={{width:'100%'}} multiline={true} label="Title" />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="preview">
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="content">
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                                <Grid item xs={6}>
                                    <ReferenceInput label="edition" source="edition" reference="hvg-editions" sort={{ field: 'title', order: 'ASC' }}>      
                                        <SelectInput source="id" sx={{width:'100%'}} optionText="title" validate={required()} format={(value) => value.id}  parse={(value) => ({id:value})  }   />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                    </Box>

        </SimpleForm>
    </Edit>
);

export const HvgmodelShow = () => (
    <Show title={<ModelShowTitleNormal />}>
        <SimpleShowLayout>
            <Box sx={{ flexGrow: 1 }}>
                       <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Labeled label="Id">
                                         <TextField source="id" disabled />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                <Labeled label="Title">
                                     <TextField source="title" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                              <Labeled label="Edition">
                                  <TextField source="edition.title" label="Edition" />
                                </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                              <Labeled label="Preview">
                                        <ImageField source="preview" multiline={true} label="preview" />
                               </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                    <Labeled label="Content">
                                         <FileField source="content" title="title" download={true} target="_blank" multiline={true} label="Content" />  
                                    </Labeled>
                            </Grid>
                            <Grid item xs={6}>
                                  <Labeled label="Date">
                                        <DateField source="date" locales="fr-FR"  label="Publication date" />
                                  </Labeled>
                            </Grid>
                        </Grid>
                    </Box>
            </SimpleShowLayout>
    </Show>
);

export const HvgmodelList = () => (
    <List >
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <ReferenceField source="editionId" reference="hvg-editions" label="Edition" />
            <ImageField source="preview"  label="preview" />
            <FileField source="content" title="title"  />
            <DateField source="date" />
            <EditButton/>
            <ShowButton />
        </Datagrid>
    </List>
);

const HvgModelListTitle = () => {
    const { id } = useParams();
    const { data, isPending, error, refetch } = useGetOne('hvg-editions', { id: id } );
    return <span> {data ? `${data.title} / Models` : ''}</span>;
}
export const HvgModelNestedCreate = () => {
    const { id} = useParams();
    return (
        <Create resource={`hvg-models`} redirect={`/admin/hvg-editions/${id}/models`}>
            <SimpleForm>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                <DateInput label="Publication date" sx={{width:'100%'}} source="date" defaultValue={new Date()} />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextInput source="title" multiline={true} sx={{width:'100%'}} label="Title"  validate={[required()]}  />
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="preview"  validate={[required()]} >
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                                <Grid item xs={12}>
                                    <FileInput source="content"  validate={[required()]} >
                                        <FileField source="src" title="title" />
                                    </FileInput>
                                </Grid>
                        {/* <TextInput source="preview" multiline={true} label="Preview" />
                        <TextInput source="content" multiline={true} label="Content" /> */}
                                <Grid item xs={6}>
                                    <ReferenceInput label="edition" source="edition" reference="hvg-editions"> 
                                            <SelectInput source="title" sx={{width:'100%'}} optionText="title" readOnly defaultValue={id} validate={required()} />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </Box>
            </SimpleForm>
        </Create>
    );
};

const HvgModelEditButton = () => { 
    const { id, modelId } = useParams();
    return(
        <EditButton component={Link}
        to={`/admin/hvg-editions/${id}/models/${modelId}`} label="Éditer" /> 
    )
}

const HvgModelShowButton = () => {
    const { id, modelId } = useParams();
    return(
        <ShowButton component={Link}
        to={`/admin/hvg-editions/${id}/models/${modelId}/show`} label="Afficher" /> 
    )
}

const HvgModelShowActions = () => (
    <TopToolbar>
        <HvgModelEditButton/>
    </TopToolbar>
);
const HvgModelShowActionsOne = () => (
    <TopToolbar>
        <HvgModelShowButton/>
    </TopToolbar>
);

export const HvgModelNestedShow = () => {
    const { id, modelId } = useParams();
    return (
        <Show resource={`hvg-models`} id={modelId} title={<ModelShowTitle />} actions={<HvgModelShowActions />}>
            <SimpleShowLayout>
                <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Labeled label="Id">
                                            <TextField source="id" disabled />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={6}>
                                    <Labeled label="Title">
                                        <TextField source="title" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={6}>
                                <Labeled label="Edition">
                                    <TextField source="edition.title" label="Edition" />
                                    </Labeled>
                                </Grid>
                                <Grid item xs={6}>
                                <Labeled label="Preview">
                                            <ImageField source="preview" multiline={true} label="preview" />
                                </Labeled>
                                </Grid>
                                <Grid item xs={6}>
                                        <Labeled label="Content">
                                            <FileField source="content" title="title" download={true} target="_blank" multiline={true} label="Content" />  
                                        </Labeled>
                                </Grid>
                                <Grid item xs={6}>
                                    <Labeled label="Date">
                                            <DateField source="date" locales="fr-FR" label="Publication date" />
                                    </Labeled>
                                </Grid>
                            </Grid>
                        </Box>
                </SimpleShowLayout>
        </Show>
    )
};

export const HvgModelNestedEdit = () => {
    const { id, modelId } = useParams();
    return (
        <Edit resource={`hvg-models`} id={modelId} title={<ModelShowTitle />} redirect={`/admin/hvg-editions/${id}/models`} actions={<HvgModelShowActionsOne />}>
            <SimpleForm>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                        <DateInput label="Publication date" sx={{width:'100%'}} source="date" defaultValue={date} />
                                  </Grid>
                                   <Grid item xs={6}>
                                        <TextInput source="title" sx={{width:'100%'}} multiline={true} label="Title" />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileInput source="preview">
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FileInput source="content">
                                            <FileField source="src" title="title" />
                                        </FileInput>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <ReferenceInput label="edition" source="edition" reference="hvg-editions" sort={{ field: 'title', order: 'ASC' }}>      
                                            <SelectInput source="id" sx={{width:'100%'}} optionText="title" validate={required()} format={(value) => value.id}  parse={(value) => ({id:value})  }   />
                                        </ReferenceInput>
                                    </Grid>
                        </Grid>
                    </Box>
            </SimpleForm>
        </Edit>
    )
};
const EmptyModel = () => {
    const { id } = useParams();
    const { data, isPending, error, refetch } = useGetOne('hvg-editions', { id: id } );
    return(
        <Box textAlign="center" m={1} sx={{width:'100%'}}>
            <div class="list-page">
                 <span class="RaList-noResults">
                        <div class="RaEmpty-message">
                            <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium RaEmpty-icon" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="InboxIcon"><path d="M19 3H4.99c-1.11 0-1.98.89-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.11-.9-2-2-2m0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19z"></path></svg>
                            <p class="MuiTypography-root MuiTypography-h4 MuiTypography-paragraph">Pas encore de Models pour {data?.title}.</p>
                            <p class="MuiTypography-root MuiTypography-body1">Voulez-vous en créer un ?</p>
                        </div>
                        <div class="RaEmpty-toolbar">
                            <CreateButton/>
                        </div>
                </span>
            </div>
        </Box>
    )
};


export const HvgModelNestedList = () => {
    const { id } = useParams();
    return (
    <List resource={`hvg-editions/${id}/models`} hasCreate={true} title={<HvgModelListTitle/>} empty={<EmptyModel/>}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="title" />
            <ImageField source="preview"  label="preview" />
            <FileField source="content" title="title"  />
            <DateField source="date" />
            <EditButton/>
            <ShowButton />
        </Datagrid>
    </List>
    );
};


