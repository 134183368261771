
import { Admin, CustomRoutes, useGetList, radiantLightTheme,radiantDarkTheme, Resource, ShowGuesser, EditGuesser } from "react-admin";
import { authProvider } from "./authProvider.js";
import { dataProvider } from "./dataProvider.js";
import { UserList } from "./components/pages/users/index";
import {PostList, PostCreate, PostEdit, PostShow} from "./components/admin/posts/index";
import {modelList, modelCreate, modelEdit, modelShow, ModelNestedCreate, ModelNestedList, ModelNestedEdit, ModelNestedShow} from "./components/admin/models/index";
import {projectList, projectCreate, projectEdit, projectShow, ProjectNestedList,ProjectNestedEdit,ProjectNestedShow } from "./components/admin/projects/index";
import {designList, designCreate, designEdit, designShow, DesignNestedCreate, DesignNestedList, DesignNestedEdit, DesignNestedShow, DesignProjectList, DesignProjectCreate} from "./components/admin/design/index";
import {HvgPostList, HvgPostCreate, HvgPostEdit, HvgPostShow} from "./components/admin/hvgposts/index";
import {HvgmodelList, HvgmodelCreate, HvgmodelEdit, HvgmodelShow, HvgModelNestedCreate, HvgModelNestedList, HvgModelNestedEdit, HvgModelNestedShow} from "./components/admin/hvgmodels/index";
import {HvgprojectList, HvgprojectCreate, HvgprojectEdit, HvgprojectShow, HvgProjectNestedList, HvgProjectNestedEdit, HvgProjectNestedShow } from "./components/admin/hvgprojects/index";
import {HvgdesignList, HvgdesignCreate, HvgdesignEdit, HvgdesignShow, HvgDesignNestedCreate, HvgDesignNestedList, HvgDesignNestedEdit, HvgDesignNestedShow, HvgDesignProjectList, HvgDesignProjectCreate} from "./components/admin/hvgdesign/index";
import {usersList, usersEdit, usersShow, usersCreate} from "./components/admin/users/index";
import {EventList, eventEdit, eventShow, eventCreate} from "./components/admin/event/index";
import {mediaList,MediaNestedList,MediaNestedEdit,MediaNestedShow, MediaNestedCreate, mediaEdit, mediaShow, mediaCreate} from "./components/admin/media/index";
import {themeList, ThemeEdit, themeCreate, ThemeShow} from "./components/admin/theme/index";
import {serviceList, serviceEdit, serviceCreate, serviceShow} from "./components/admin/service/index";
import {tagsList, tagsEdit, tagsCreate, tagsShow} from "./components/admin/tags/index";
import BookIcon from '@mui/icons-material/Book';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ServicesIcon from '@mui/icons-material/DesignServices';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CategoryIcon from '@mui/icons-material/Category';
import { Route } from "react-router-dom";
import FarmForumPage from "./components/pages/farmforum/index.js";
import { i18nProvider } from './i18nProvider';
import MyLoginPage from './login';

export const StoreAdmin = () => (
<Admin disableTelemetry  basename="/admin" theme={radiantLightTheme}  darkTheme={radiantDarkTheme} dataProvider={dataProvider} authProvider={authProvider} i18nProvider={i18nProvider}>
       {permissions => (
        <>
             {permissions === 'Admin' ?
                <>
                <Resource name="editions" list={PostList} show={PostShow} edit={PostEdit} create={PostCreate} icon={BookIcon}>
                            <Route path=":id/models/create" element={<ModelNestedCreate />} />
                            <Route path=":id/models" element={<ModelNestedList />} />
                            <Route path=":id/models/:modelId" element={<ModelNestedEdit />} />
                            <Route path=":id/models/:modelId/shoW" element={<ModelNestedShow />} />
                            <Route path=":id/projects" element={<ProjectNestedList />} />
                            <Route path=":id/projects/:projectId" element={<ProjectNestedEdit />} />
                            <Route path=":id/projects/:projectId/shoW" element={<ProjectNestedShow/>} />
                            <Route path=":id/projects/:projectId/design/create" element={<DesignNestedCreate />} />
                            <Route path=":id/projects/:projectId/design" element={<DesignNestedList />} />
                            <Route path=":id/projects/:projectId/design/:designsId" element={<DesignNestedEdit />} />
                            <Route path=":id/projects/:projectId/design/:designsId/shoW" element={<DesignNestedShow/>} />
                 </Resource>
                <Resource name="models" list={modelList} show={modelShow} edit={modelEdit} create={modelCreate} icon={BusinessCenterIcon}/>
                <Resource name="projects" list={projectList} edit={projectEdit} show={projectShow} icon={CorporateFareIcon} >
                            <Route path=":id/designs/create" element={<DesignProjectCreate />} />
                            <Route path=":id/designs" element={<DesignProjectList />} />
                            <Route path=":id/designs/:designsId" element={<DesignNestedEdit />} />
                            <Route path=":id/designs/:designsId/shoW" element={<DesignNestedShow/>} />
                </Resource>
                <Resource name="design" list={designList} show={designShow} create={designCreate} edit={designEdit} icon={ServicesIcon} />
                <Resource name="event" list={EventList} create={eventCreate} edit={eventEdit} show={eventShow} icon={EmojiEventsIcon}>
                            <Route path=":id/media/create" element={<MediaNestedCreate />} />
                             <Route path=":id/media" element={<MediaNestedList />} />
                             <Route path=":id/media/:mediaId" element={<MediaNestedEdit />} />
                             <Route path=":id/media/:mediaId/show" element={<MediaNestedShow />} />
                </Resource>
                <Resource name="media" list={mediaList}  create={mediaCreate} edit={mediaEdit} show={mediaShow} icon={PermMediaIcon}/>
                <Resource name="theme" list={themeList}  create={themeCreate} edit={ThemeEdit} show={ThemeShow} icon={DarkModeIcon}/>
                <Resource name="service" list={serviceList}  create={serviceCreate} edit={serviceEdit} show={serviceShow} icon={DesignServicesIcon}/>
                <Resource name="tag" list={tagsList}  create={tagsCreate} edit={tagsEdit} show={tagsShow} icon={CategoryIcon}/>
                <Resource name="hvg-editions" list={HvgPostList} show={HvgPostShow} edit={HvgPostEdit} create={HvgPostCreate} icon={BookIcon}>
                            <Route path=":id/models/create" element={<HvgModelNestedCreate />} />
                            <Route path=":id/models" element={<HvgModelNestedList />} />
                            <Route path=":id/models/:modelId" element={<HvgModelNestedEdit />} />
                            <Route path=":id/models/:modelId/shoW" element={<HvgModelNestedShow />} />
                            <Route path=":id/projects" element={<HvgProjectNestedList />} />
                            <Route path=":id/projects/:projectId" element={<HvgProjectNestedEdit />} />
                            <Route path=":id/projects/:projectId/shoW" element={<HvgProjectNestedShow/>} />
                            <Route path=":id/projects/:projectId/design/create" element={<HvgDesignNestedCreate />} />
                            <Route path=":id/projects/:projectId/design" element={<HvgDesignNestedList />} />
                            <Route path=":id/projects/:projectId/design/:designsId" element={<HvgDesignNestedEdit />} />
                            <Route path=":id/projects/:projectId/design/:designsId/shoW" element={<HvgDesignNestedShow/>} />
                 </Resource>
                <Resource name="hvg-models"  list={HvgmodelList} show={HvgmodelShow} edit={HvgmodelEdit} create={HvgmodelCreate} icon={BusinessCenterIcon}/>
                <Resource name="hvg-projects" list={HvgprojectList} edit={HvgprojectEdit} show={HvgprojectShow} icon={CorporateFareIcon} >
                            <Route path=":id/designs/create" element={<HvgDesignProjectCreate />} />
                            <Route path=":id/designs" element={<HvgDesignProjectList />} />
                            <Route path=":id/designs/:designsId" element={<HvgDesignNestedEdit />} />
                            <Route path=":id/designs/:designsId/shoW" element={<HvgDesignNestedShow/>} />
                </Resource>
                <Resource name="hvg-design"  list={HvgdesignList} show={HvgdesignShow} create={HvgdesignCreate} edit={HvgdesignEdit} icon={ServicesIcon} />
                <Resource name="users" list={usersList} edit={usersEdit} create={usersCreate} show={usersShow} icon={PersonAddAltIcon}/>
              </> : null}
         </>
        )}
 </Admin>
);